import React from "react"
import { Container, Row, Col } from 'react-bootstrap';
import JasonImg from '../images/bio_lipps.jpg';
import DorisImg from '../images/Doris.jpg';
import WCImg from '../images/wcstewart.jpg';
import JayImg from '../images/bio_hasselbeck.jpg';

import Layout from "../components/layout"
import SEO from "../components/seo"

const CrewPage = (props) => (
    <Layout location={props.location}>
      <SEO title="Home" description="hello world"/>
      <h1 style={styles.header}>Our Crew</h1>
      <div style={styles.body}>
      <h5 style={styles.italic}>Our Crew Keeps You on Course.</h5>
      <p>
        At the Coastal Advisory Group our award-winning team is dedicated to helping you achieve your financial goals by 
        providing you the full-scope of financial planning services. See how our years of experience and specialized 
        knowledge can make a difference!
      </p>
      <Container fluid={true}>
        <Row style={{padding: '40px 0'}}>
          <Col lg={3}>
            <img src={JasonImg} style={styles.bioImg} />
            <p>
              <span style={{color: '#8d1b38'}}>Jason R. Lipps</span> <br/>
              Investment Adviser Representative<br/>
              <a href="tel:+15136191100">513-619-1100</a> Ext. 8
            </p>
          </Col>
          <Col>
            For over 10 years, Jason Lipps has been driven by his passion to help his clients learn sound investment 
            principles so they can make smarter decisions and enjoy greater freedom in their total financial lives. He 
            offers his clients one-on-one meetings outside of the office to ensure their convenience and comfortable 
            discussion.<br/>
            After graduating from Miami University with a Bachelor of Science in Finance, he started his own business as 
            an Investment Advisor Representative and focused on Comprehensive Financial Planning with Brecek & Young 
            Advisors. In 2006, he moved his practice to Pension Corporation of America, and in 2010 he became a Wealth 
            Manager with the Coastal Advisory Group, winning the 2011 and 2012 FIVE STAR Wealth Manager Awards.<br/><br/>
            He and his wife, Kerri, are the proud parents of their first daughter. He also enjoys golfing, reading and 
            taking his dog to the park. He holds an Ohio Life, Health, and Disability Insurance license.
          </Col>
        </Row>
        <hr/>
        <Row style={{padding: '40px 0'}}>
          <Col lg={3}>
            <img src={DorisImg} style={styles.bioImg} />
            <p>
              <span style={{color: '#8d1b38'}}>Doris A. Candito</span> <br/>
              Investment Adviser Representative<br/>
              <a href="tel:+15136191100">513-619-1100</a> Ext. 5
            </p>
          </Col>
          <Col>
            Doris began her distinguished thirty five year career with Dean Witter, now Morgan Stanley, where she worked for 
            nine years as a financial advisor before joining Coastal Equities 26 years ago.<br/>
            Doris joined Coastal, which gave her a more flexible schedule, while raising her family of three children with her 
            husband Joe. She grew up in Portsmouth, Ohio and took up residence in Cincinnati after completing her college 
            education at Ohio University.<br/><br/>
            Through the years of ups and downs in the financial markets, Doris has continued to maintain her conservative 
            philosophy of investing while building long-term relationships with clients. In the past she has conducted 
            educational seminars on retirement planning and charitable giving and appeared on Channel 19 TV in Cincinnati.<br/><br/>
            Doris has a B.S. and Master’s degree from Ohio University. Multi-talented, she completed two marathons and speaks 
            both German and Spanish.
          </Col>
        </Row>
        <hr/>
        <Row style={{padding: '40px 0'}}>
          <Col lg={3}>
            <img src={WCImg} style={styles.bioImg} />
            <p>
              <span style={{color: '#8d1b38'}}>W.C. Stewart</span> <br/>
              Investment Adviser Representative<br/>
              <a href="tel:+15136191100">513-619-1100</a> Ext. #
            </p>
          </Col>
          <Col>
            W.C. has over 30 years of experience in the insurance and finance industry. He is a graduate of Lipscomb University in Nashville, Tennessee and earned a B.S. degree in Business Management. He helped develop a unique market in the school systems offering employee benefits along with retirement plans. In 1994, he received his securities license and continued his distinguished career helping families with their retirement planning. W.C. offers one on one consulting in his office in Covington, Kentucky and helps each individual as they develop a plan together helping ensure they enjoy their retirement years. W.C. believes that proper and strategic planning, can help clients manage, grow and protect the family’s wealth.<br/><br/>
            W.C. and his wife, Beth, enjoy volunteering in the community and are the proud parents of two sons. He enjoys attending and watching U.K. basketball games, traveling, taking an occasional cruise and spending time with his family and friends.
          </Col>
        </Row>
        <hr/>
        <Row style={{padding: '40px 0'}}>
          <Col lg={3}>
            <img src={JayImg} style={styles.bioImg} />
            <p>
              <span style={{color: '#8d1b38'}}>Jay Hasselbeck</span> <br/>
              Database Manager<br/>
              <a href="tel:+15136191100">513-619-1100</a> Ext. 7
            </p>
          </Col>
          <Col>
            As Database Manager, Jay Hasselbeck helps keep private wealth management private by maintaining our 
            databases, reviewing accounting transactions and assisting in communications and investor relations. 
            Jay’s diligence and integrity are unparalleled when it comes to serving our select client family. He 
            is the go-to guy for technology issues and oversees the audio-visual installations for seminars and 
            conferences. Jay received his Associate Degree in Accounting from Northern Kentucky Technical College.
            <br/>
            When not in the office, he is an avid player of Fantasy Football with an affinity for movie-going and 
            playing recreational basketball and golf.
          </Col>
        </Row>
      </Container>
    </div>
  </Layout>
)

let styles={
  header: {
    color: '#8d1b38',
    padding: '35px 15px 0 25px'
  },
  primaryText: {
    color: '#8d1b38'
  },
  bullet: {
    paddingLeft: '30px'
  },
  secondaryText: {
    color: '#083f95',
    fontWeight: '700'
  },
  italic: {
    color: '#8d1b38',
    fontStyle: 'italic'
  },
  body: {
    padding: '0 60px 30px 60px',
    color: 'black'
  },
  bioImg: {
    width: '100%',
    maxWidth: '166px'
  }
}

export default CrewPage
